<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Orientation Assessment Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="d-flex">
                <label class="col-form-label me-1">Program Name</label>
                <div style="width: 150px">
                  <v-select
                    label="short_name"
                    v-model="program"
                    :options="programs"
                    :clearable="false"
                    :selectable="(options) => options.id != program.id"
                    @option:selected="
                      getFilterData(program, batch, month, assessor)
                    "
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Batch</label>
                <div style="width: 150px">
                  <v-select
                    label="batch_no"
                    v-model="batch"
                    :options="batches"
                    :clearable="false"
                    :selectable="(options) => options.id != batch.id"
                    @option:selected="
                      getFilterData(program, batch, month, assessor)
                    "
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Month</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="month"
                    :options="allMonths"
                    :clearable="false"
                    :selectable="(options) => options.id != month.id"
                    @option:selected="
                      getFilterData(program, batch, month, assessor)
                    "
                  >
                  </v-select>
                </div>

                <label class="col-form-label ms-2 me-1">Assessor</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="assessor"
                    :options="assessors"
                    :clearable="false"
                    :selectable="(options) => options.id != assessor.id"
                    @option:selected="
                      getFilterData(program, batch, month, assessor)
                    "
                  >
                  </v-select>
                </div>

                <div @click="refreshData" class="icon-css">
                  <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                </div>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="ori-assessment-list-report"
                style="width: 1440px; margin-right: 1.2em"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No</th>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Program Name</th>
                    <th>Batch</th>
                    <th>HRD Assessment Result</th>
                    <th>Behavior Survey Result</th>
                    <th>Final Result</th>
                    <th class="d-none">Month</th>
                    <th class="d-none">Assessor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in allOriList" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.employee_id }}</td>
                    <td>{{ item.employee_name }}</td>
                    <td>{{ item.program_name }}</td>
                    <td>{{ item.batch_name }}</td>
                    <td class="text-center">
                      {{ item.hrd_assessment_result + " %" }}
                    </td>
                    <td class="text-center">
                      {{ item.behavior_survey_result + " %" }}
                    </td>
                    <td>
                      {{
                        item.evaluation_score +
                        " %" +
                        "   ( " +
                        item.assessment_result +
                        " )"
                      }}
                    </td>
                    <td class="d-none">{{ getMonth(item.created_at) }}</td>
                    <td class="d-none">{{ item.assessor_name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { months } from "../../../utlis/months";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";

export default {
  data() {
    return {
      programs: [],
      batches: [],
      allMonths: [],
      assessors: this.$store.getters["odoo/getAllEmployees"],
      assessor: "",
      month: "",
      program: "",
      batch: "",
      allOriList: [],
      title: "Orientation Assessment Report Lists",
      headers: [
        "No.",
        "Employee ID",
        "Employee Name",
        "Program Name",
        "Batch",
        "HRD Assessment Result",
        "Behavior Survey Result",
        "Final Result",
      ],
      pdfLoading: false,
      excelLoading: false,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
  },
  methods: {
    getMonth(month) {
      return moment(month).format("MMMM");
    },
    async getPrograms() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getBatch() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/program-batches/list`)
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getAllOrientationList() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/orientation-assessment-report`)
        .then((res) => {
          this.allOriList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.toast.error("Something went wrong.");
        });
      this.loading = false;
      this.$Progress.finish();
      $("#ori-assessment-list-report").DataTable().draw();
    },
    getFilterData(program, batch, month, assessor) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        let programFilter = program?.name;
        let programStage = data[3];
        let batchFilter = batch?.batch_no;
        let batchStage = data[4];
        let monthFilter = month?.name;
        let monthStage = data[8];
        let assessorFilter = assessor?.name;
        let assessorStage = data[9];
        if (
          (programFilter == programStage || !programFilter) &&
          (batchFilter == batchStage || !batchFilter) &&
          (monthFilter == monthStage || !monthFilter) &&
          (assessorFilter == assessorStage || !assessorFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#ori-assessment-list-report").DataTable().draw();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#ori-assessment-list-report").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#ori-assessment-list-report")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[8] = "";
        item[9] = "";
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);
      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#ori-assessment-list-report")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];
      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[8] = "";
        item[9] = ""; //removing month and assessor from report can't use slice cuz it will warnning by databale
        sheetData.push(item);
      });
      exportExcel(sheetData, "Orientation Assessment");
      this.excelLoading = false;
    },
    refreshData() {
      this.program = "";
      this.batch = "";
      this.allOriList = [];
      this.month = "";
      this.assessor = "";
      this.clearFilter();

      this.getPrograms();
      this.getBatch();
      this.getAllOrientationList();
    },
  },
  created() {
    this.allMonths = months;
    this.clearFilter();
    this.getPrograms();
    this.getBatch();
    this.getAllOrientationList();
  },
};
</script>
